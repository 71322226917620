body {
  background-color: #1a1a1a;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.App {
  text-align: center;
}

#customRange::webkit-slider-thumb {
  width: 40px;
}

.btn-outline-light {
  background-color: transparent !important;
  color: #ffffff !important;
}

.btn-outline-success {
  background-color: transparent !important;
  color: #28a745 !important;
}

/* ==== SHAPE SLIDER ==== */

input[type=range] {
  height: 26px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 13px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #ffffff20;
  border-radius: 25px;
  border: 0px solid #000101;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 15px;
  width: 40px;
  border-radius: 7px;
  background: #ffffff25;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -1px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #f9b1ff;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 13px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #f9b1ff;
  border-radius: 25px;
  border: 0px solid #000101;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  /* width: 5px; */
  border-radius: 7px;
  background: #f9b1ff;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 13px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #f9b1ff;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
  background: #f9b1ff;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  /* width: 39px; */
  border-radius: 7px;
  background: #f9b1ff;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #f9b1ff;
}

input[type=range]:focus::-ms-fill-upper {
  background: #fcd6ff;
}

/* ======== MEDIA QUERIES ======== */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */

/* } */